<template>
  <Portlet
    :title="'Contact Tracing Statistics'"
    icon="table"
  >
    <LoadingPlaceholder v-if="loading" />
    <div
      v-else
      class="contactTracingStatistics"
    >
      <form>
        <div class="form-group col-6">
          <label
            for="client"
            class="form-label"
          >Client</label>
          <Multiselect
            id="clients"
            v-model="client"
            tag-placeholder="Not found"
            placeholder="Select a Client"
            :options="clients"
            select-label=""
            :multiple="false"
            :taggable="true"
            :hide-selected="true"
            :searchable="true"
            :close-on-select="true"
            :custom-label="clientLabel"
            class="mb-4"
          />
        </div>
        <div
          class="form-check mb-3 col-6 ml-3"
        >
          <input
            id="lastMonthCheck"
            v-model="lastMonth"
            type="checkbox"
            class="form-check-input"
          >
          <label
            class="form-check-label"
            for="lastMonethCheck"
          >Last Month</label>
        </div>
        <template v-if="!lastMonth">
          <div class="form-group col-6">
            <label>{{ $t('from') }}</label>
            <date-picker
              v-model="selectedDateFrom"
              :editable="true"
              :clearable="false"
              type="date"
              lang="en"
              format="DD.MM.YYYY"
              value-type="date"
              :first-day-of-week="1"
              class="ml-3"
            />
            <label class="ml-4">{{ $t('to') }}</label>
            <date-picker
              v-model="selectedDateTo"
              :editable="true"
              :clearable="false"
              type="date"
              lang="en"
              format="DD.MM.YYYY"
              value-type="date"
              :first-day-of-week="1"
              class="ml-3"
            />
          </div>
        </template>
      </form>
      <button
        slot="button"
        class="btn btn-primary mt-4 mb-3"
        :disabled="!client || (!lastMonth && (!selectedDateFrom || !selectedDateTo))"
        @click="generateReport()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="file-alt"
        />Generate Report
      </button>
    </div>
  </Portlet>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'ContactTracingStatistics',
  components: {
    Multiselect: () => import('vue-multiselect'),
    DatePicker
  },
  mixins: [
    dateTimeMixin,
  ],
  data () {
    return {
      loading: false,
      clients: null,
      client: null,
      lastMonth: true,
      selectedDateFrom: null,
      selectedDateTo: null
    }
  },
  created () {
    this.getClients();
  },
  methods: {
    clientLabel ({ clientName }) {
      return `${clientName}`
    },
    getClients () {
      this.loading = true;
      this.axios.get(`/ContactTracing/GetTracyClients`)
      .then((response) => {
          if(response && response.data) {
            this.clients = response.data;
          }
        })
        .finally(() => {
          this.loading = false;
        })
    },
    generateReport () {
      this.loading = true;
      
       var req = {
        lastMonth: this.lastMonth,
        clientId: this.client.clientId,
        startDate: this.selectedDateFrom,
        endDate: this.selectedDateTo,
        clientName :this.client.clientName
      };

      this.axios.post(`/ContactTracing/GetTracyExcelReport`, req)
      .then((response) => {
        if (response) {
          const link = document.createElement('a');
          link.href = 'data:application/octet-stream;charset=utf-8;base64,' + response.data;
          link.target = '_blank';
          if(this.lastMonth) {
            link.download = `${this.client.clientName}_${this.moment().subtract(1, 'months').startOf('month').format('MM-YYYY')}.xlsx`;
          }
          else {
            link.download = `${this.client.clientName}_${this.toMoment(this.selectedDateFrom).format('DD-MM-yyyy')}_${this.toMoment(this.selectedDateTo).format('DD-MM-yyyy')}.xlsx`;
          }
          link.click();
        }
      })
      .finally(() => {
        this.loading = false;
        this.$emit("reloadAuditLogs");
      });
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>